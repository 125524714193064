<template>
    <super-admin-auth-layout>
        <div @click="errorMessage=''" class="mt-4 p-3 p-lg-0">
            <div class="text-center mb-5">
                <h5 class="fs-lg-6 mb-1 font-inter-semi-bold">Set a New Password</h5>
                <p class="font-inter-medium fs--1 ml-2 text-5 text-center">
                    Please enter your new password
                </p>
            </div>
            <s-form>
                <validated-input label="Enter new password" placeholder="" type="password"
                                 v-model="model.email" class="text-secondary mb-1 c-form-input"
                                 :disabled="loading" :rules="rules.email"/>
                <validated-input label="Confirm new password" placeholder="" type="password"
                                 v-model="model.email" class="text-secondary mb-1 c-form-input"
                                 :disabled="loading" :rules="rules.email"/>
                <validated-checkbox label="Show password"></validated-checkbox>
                <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
                <div class="text-center mt-5">
                    <btn class="px-5 font-inter-medium" block text="Submit" loading-text="Validating..." size=""
                         :disabled="loading" :loading="loading"/>
                </div>
            </s-form>
        </div>
    </super-admin-auth-layout>
</template>

<script>
import SuperAdminAuthLayout from '@/views/auth/super-admin-auth/SuperAdminAuthPageLayout';
import urls from '@/data/urls';

export default {
    name       : 'SuperAdminSetNewPassword',
    components : { SuperAdminAuthLayout },
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
