<template>
    <div class="image auth-bg">
        <div class="py-3 fl-x-cc logo-container" style="width: 100%;">
            <img class="logo" src="../../../assets/web/logos/BANK-E-AUCTION-LOGO-PNG-B.png" alt="logo">
        </div>
        <div class="container mt-5">
            <div class="fl-x fl-j-c">
                <div class="p-lg-5 p-2 bg-2 border-r-2 w-lg-40">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'SuperAdminAuthLayout'
};
</script>

<style scoped lang="scss">
    .full-page {
        height: 100vh;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .auth-bg {
        //width: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        background: url("../../../assets/web/auth/login_img_mobile.png");
        @media (min-width: 992px) {
            background: url("../../../assets/web/auth/login-web.jpg");
        }
    }

    .side-heading {
        position: absolute;
        top: 50%;
        left: 40%;
        @media (min-width: 992px) {
            left: 45%;
        }
        transform: translate(-50%, -50%);
        z-index: 4 !important;

        h2 {
            font-size: 1.8rem !important;
        }
    }

    .overlay-blue {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(1, 76, 145, 0.4);
        //z-index: -1;
    }

    /*.image {*/
    /*    background: url("../../assets/web/homee/banner.png");*/
    /*}*/

    .link {
        cursor: pointer;
    }

    .logo-container {

        .logo {
            display: block !important;
            padding-left: 0 !important;
            height: 2rem;
        }

    }
</style>
